import * as React from "react";
import $ from "jquery-slim";
import { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  // InputGroup,
  // FormControl,
  // Tab,
  // Dropdown
} from "react-bootstrap";
import opsioLogo from "../images/opsio-logo.svg";
import searchIcon from "../images/searchIcon.svg";
import LanguageIcon from "./LanguageIcon";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";
import Select, { components } from "react-select";

//import HeaderMenu from "./Navigation/headermenu";
import Navigation from "./Navigation/navigation";

// import Scrollbars from 'react-custom-scrollbars'
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";

const Header = ({ slug, locale, headerData, setSolution, props }) => {
  // const handleClick = id => {
  //   const myElement = document.getElementById(id)
  //   if (myElement) {
  //     myElement.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }

  const flags =
    locale === "sv"
      ? {
          en: "Engelska (US)",
          "en-IN": "Engelska (IN)",
          sv: "Svenska (SE)",
        }
      : {
          en: "English (US)",
          "en-IN": "English (IN)",
          sv: "Swedish (SE)",
        };
  if (typeof window !== "undefined") {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".mina_header").addClass("stickyHeader");
      } else {
        $(".mina_header").removeClass("stickyHeader");
      }
    });
  }

  const initialLang = flags[locale];

  // const [serviceSubMenu, setServiceSubMenu] = useState(false)
  const [device, setDevice] = useState("");
  // const [show, setShow] = useState(false)
  // const [show1, setShow1] = useState(false)
  // const [showIndustryList, setShowIndustryList] = useState(false)
  // const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false);
  const [langTitle, setLangTitle] = useState(initialLang);
  const [searchValue, setSearchValue] = useState();
  const [toggleMenuBtn, setToggleMenuBtn] = useState("defaultBtn22");
  const [isHovered, setIsHovered] = useState(false);
  // const handleToggleBtn = () => {
  //   if (toggleMenuBtn === 'defaultBtn22') {
  //     setToggleMenuBtn('overfHide')
  //   } else {
  //     setToggleMenuBtn('defaultBtn22')
  //   }
  // }

  // const showDropdown = e => {
  //   setShow(!show)
  // }
  // const hideDropdown = e => {
  //   setShow(false)
  // }
  // const showDropdown1 = e => {
  //   setShow1(!show1)
  // }
  // const hideDropdownIndustryList = e => {
  //   setShowIndustryList(false)
  // }
  // const showDropdownIndustryList = e => {
  //   setShowIndustryList(!showIndustryList)
  // }
  // const hideDropdown1 = e => {
  //   setShow1(false)
  // }
  // const showDropdown2 = e => {
  //   setShow2(!show2)
  // }
  // const hideDropdown2 = e => {
  //   setShow2(false)
  // }
  const showDropdown3 = (e) => {
    setShow3(!show3);
  };
  const hideDropdown3 = (e) => {
    setShow3(false);
  };
  // const getResourcesList = headerData ? headerData.resources.value : []
  // const getSolutionList = headerData ? headerData.solution.data : {}
  // const serviceListAWS = headerData ? headerData.solution.data.servicesAWS : []
  // const serviceListAzure = headerData
  //   ? headerData.solution.data.servicesAzure
  //   : []
  // const serviceListGCP = headerData ? headerData.solution.data.servicesGCP : []

  // const technologyList = headerData ? headerData.solution.data.technologies : []

  // const technologyList2 = headerData
  //   ? headerData.solution.data.technologies2
  //   : []

  const styles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: state.isFocused ? null : null,
      width: "199px",
    }),
    input: (base, state) => ({
      ...base,
      color: "#FFF",
    }),
    singleValue: () => ({
      color: "#FFF",
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "0.8vw",
      fontStyle: "Open Sans",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }),
    indicatorSeparator: () => {}, // removes the "stick"
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={searchIcon}
            alt="opsioLogo"
            onClick={() => handleKeyDown("search")}
          />
        </components.DropdownIndicator>
      )
    );
  };
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlog {
        edges {
          node {
            locale
            slug
            title
            heading1
          }
        }
      }
      allStrapiPressmedia {
        edges {
          node {
            locale
            slug
            title
            heading
          }
        }
      }
      allStrapiCloudnew {
        edges {
          node {
            locale
            slug
            title
            heading
          }
        }
      }
    }
  `);
  const getallcloudnewsblog = data.allStrapiCloudnew.edges
    .filter((node) => node.node.locale === locale)
    .map((val) => ({
      value: `/resource/cloud-news/${val.node.slug}`,
      label: val.node.heading,
    }));
  const getallblogs = data.allStrapiPressmedia.edges
    .filter((node) => node.node.locale === locale)
    .map((val) => ({
      value: `/resource/press-and-media/${val.node.slug}`,
      label: val.node.heading,
    }));
  const getallPressMedia = data.allStrapiBlog.edges
    .filter((node) => node.node.locale === locale)
    .map((val) => ({
      value: `/resource/blog/${val.node.slug}`,
      label: val.node.heading,
    }));
  const options = [...getallPressMedia, ...getallblogs, ...getallcloudnewsblog];
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event === "search") {
      const path = ``;
      navigate(`${path}${searchValue.value}`);
    }
  };
  // const renderScrollbar = props => {
  //   return (
  //     <div style={{ height: 300 }}>
  //       <Scrollbars>{props.children}</Scrollbars>
  //     </div>
  //   )
  // }

  //const getData = data && data.filter((node) => node.node.locale === props.locale);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <html lang={locale} />
        {/* <script
          type='text/javascript'
          src='https://www.bugherd.com/sidebarv2.js?apikey=b5pqagka5nagolq9lfme8a'
          async='true'
        ></script> */}
        <body className={toggleMenuBtn} />
      </Helmet>
      <div className="mina_header">
        <header className="opsioHeader">
          <Navbar collapseOnSelect expand="lg" variant="dark">
            <Navbar.Brand>
              <Link to={`/`}>
                <img src={opsioLogo} alt="opsioLogo" />
              </Link>
            </Navbar.Brand>

            {/* <Navbar.Toggle
              onClick={handleToggleBtn}
              ria-controls='responsive-navbar-nav'
            >
              <HumbergerMenu />
            </Navbar.Toggle> */}

            <div className="d-flex">
              <Navigation slug={slug} headerData={headerData} locale={locale} />

              <Navbar.Collapse
                className="headerMenu"
                id="responsive-navbar-nav"
              >
                <div className="menusWrap">
                  {/* <HeaderMenu headerData={getData.length > 0 && getData[0].node.navbar} /> */}

                  <label
                    className="searchInput"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Select
                      autoSize={true}
                      onInputChange={(e) => setSearchValue(e)}
                      styles={styles}
                      onChange={(e) => navigate(`${e.value}`)}
                      options={searchValue ? options : []}
                      noOptionsMessage={(val) => headerData.searchNoOptions}
                      placeholder={isHovered ? "" : headerData.search}
                      isSearchable="true"
                      components={{
                        DropdownIndicator,
                      }}
                      isClearable="true"
                    />
                  </label>
                  <MediaQuery maxWidth={1024}>
                    <div className="langOpt">
                      <LanguageIcon
                        flag={
                          langTitle === flags["en"]
                            ? "en"
                            : langTitle === flags["sv"]
                            ? "sv"
                            : "in"
                        }
                      />
                      <NavDropdown
                        title={langTitle}
                        id="collasible-nav-dropdown-2"
                        className="landropMenu"
                        show={show3}
                        onToggle={showDropdown3}
                      >
                        <div className="lanMenu">
                          {slug === "/" ? (
                            <Nav.Item>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["sv"])}
                                href={`https://opsio.se/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"sv"} />
                                {flags["sv"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en"])}
                                href={`https://opsiocloud.com/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"en"} />
                                {flags["en"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en-IN"])}
                                href={`https://opsio.in/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"in"} />
                                {flags["en-IN"]}
                              </a>
                            </Nav.Item>
                          ) : (
                            <Nav.Item>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["sv"])}
                                href={`https://opsio.se/${slug}/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"sv"} />
                                {flags["sv"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en"])}
                                href={`https://opsiocloud.com/${slug}/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"en"} />
                                {flags["en"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en-IN"])}
                                href={`https://opsio.in/${slug}/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"in"} />
                                {flags["en-IN"]}
                              </a>
                            </Nav.Item>
                          )}
                        </div>
                      </NavDropdown>
                    </div>
                  </MediaQuery>
                  <MediaQuery minWidth={1025}>
                    <div className="langOpt">
                      <LanguageIcon
                        flag={
                          langTitle === flags["en"]
                            ? "en"
                            : langTitle === flags["sv"]
                            ? "sv"
                            : "in"
                        }
                      />
                      <NavDropdown
                        title={langTitle}
                        id="collasible-nav-dropdown-1"
                        className="landropMenu"
                        show={show3}
                        onMouseEnter={showDropdown3}
                        onMouseLeave={hideDropdown3}
                      >
                        <div className="lanMenu">
                          {slug === "/" ? (
                            <Nav.Item>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["sv"])}
                                href={`https://opsio.se/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"sv"} />
                                {flags["sv"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en"])}
                                href={`https://opsiocloud.com/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"en"} />
                                {flags["en"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en-IN"])}
                                href={`https://opsio.in/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"in"} />
                                {flags["en-IN"]}
                              </a>
                            </Nav.Item>
                          ) : (
                            <Nav.Item>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["sv"])}
                                href={`https://opsio.se/${slug}/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"sv"} />
                                {flags["sv"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en"])}
                                href={`https://opsiocloud.com/${slug}/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"en"} />
                                {flags["en"]}
                              </a>
                              <a
                                className="nav-link"
                                onClick={() => setLangTitle(flags["en-IN"])}
                                href={`https://opsio.in/${slug}/`}
                                eventKey="first"
                              >
                                <LanguageIcon flag={"in"} />
                                {flags["en-IN"]}
                              </a>
                            </Nav.Item>
                          )}
                        </div>
                      </NavDropdown>
                    </div>
                  </MediaQuery>
                </div>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </header>
      </div>
    </React.Fragment>
  );
};
export default Header;
