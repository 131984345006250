import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Nav, NavDropdown } from 'react-bootstrap'
import '../../styles/navigation.css'
import LanguageIcon from '../LanguageIcon'
import servicemenuIcon from '../../images/servicemenuIocn.svg'
import technologiesmenuIcon from '../../images/technologiesmenuIcon.svg'
import AwsIcon from '../../images/aws-icon.svg'
import AzureIcon from '../../images/azure-icon.webp'
import CloudIcon from '../../images/cloud-icon.svg'
import PressnewsMenu from '../../images/assets/pressnewsMenu.svg'
import WhitePapermenu from '../../images/assets/whitePapermenu.svg'
import LatestnewsMenu from "../../images/assets/latestnewsMenu.svg";
import BlogsMenu from "../../images/assets/blogsMenu.svg";


const Navigation = ({ slug, headerData, locale }) => {
  const getResourcesList = headerData ? headerData.resources.value : []
  const getSolutionList = headerData ? headerData.solution.data : {}
  const serviceListAWS = headerData ? headerData.solution.data.servicesAWS : []
  const serviceListAzure = headerData
    ? headerData.solution.data.servicesAzure
    : []
  const serviceListGCP = headerData ? headerData.solution.data.servicesGCP : []

  const technologyList = headerData ? headerData.solution.data.technologies : []

  const technologyList2 = headerData
    ? headerData.solution.data.technologies2
    : []

  const flags =
    locale === 'sv'
      ? {
          en: 'Engelska (US)',
          'en-IN': 'Engelska (IN)',
          sv: 'Svenska (SE)'
        }
      : {
          en: 'English (US)',
          'en-IN': 'English (IN)',
          sv: 'Swedish (SE)'
        }

  const initialLang = flags[locale]
  const [show3, setShow3] = useState(false)

  const [langTitle, setLangTitle] = useState(initialLang)
  const showDropdown3 = e => {
    setShow3(!show3)
  }
  const hideDropdown3 = e => {
    setShow3(false)
  }

  return (
    <div className='headerMenu rubyMenu'>
      <div className='ruby-menu-demo-header'>
        <div className='ruby-wrapper'>
          <ul className='ruby-menu'>
            <li>
              <Link to='#' className='dropdown-toggle'>
               Cloud Solutions
              </Link>
              <ul className='solutionmenu'>
                <h6>{getSolutionList.name}</h6>
                <li>
                  <Link to='#'>
                    <img
                      src={servicemenuIcon}
                      alt='servicemenuIcon'
                      className='icon'
                    />
                    {getSolutionList.value[0]}
                  </Link>
                  <ul className='servicesmenu'>
                  <li>
                      <Link to='#'>
                        <img
                          src={CloudIcon}
                          alt='CloudIcon'
                          className='icon'
                          width={15}
                        />
                        {getSolutionList.value[3]}
                      </Link>
                      <div className='solutionsubmenu'>
                        <div className='ruby-row'>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListGCP[0].name}</label>
                              {serviceListGCP[0].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListGCP[1].name}</label>
                              {serviceListGCP[1].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListGCP[2].name}</label>
                              {serviceListGCP[2].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListGCP[3].name}</label>
                              {serviceListGCP[3].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      {' '}
                      <Link to='#'>
                        <img
                          src={AwsIcon}
                          alt='AwsIcon'
                          className='icon'
                          width={15}
                        />
                        {getSolutionList.value[1]}
                      </Link>
                      <div className='solutionsubmenu'>
                        <div className='ruby-row'>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAWS[0].name}</label>
                              {serviceListAWS[0].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAWS[1].name}</label>
                              {serviceListAWS[1].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAWS[2].name}</label>
                              {serviceListAWS[2].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAWS[3].name}</label>
                              {serviceListAWS[3].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to='#'>
                        <img
                          src={AzureIcon}
                          alt='AzureIcon'
                          className='icon'
                          width={15}
                        />
                        {getSolutionList.value[2]}
                      </Link>
                      <div className='solutionsubmenu'>
                        <div className='ruby-row'>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAzure[0].name}</label>
                              {serviceListAzure[0].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAzure[1].name}</label>
                              {serviceListAzure[1].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAzure[2].name}</label>
                              {serviceListAzure[2].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAzure[3].name}</label>
                              {serviceListAzure[3].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                   
                  </ul>
                </li>
                <li>
                  <Link to='#'>
                    <img
                      src={technologiesmenuIcon}
                      alt='technologiesmenuIcon'
                      className='icon'
                    />
                    {getSolutionList.value[4]}
                  </Link>
                  <div className='techsubmenu'>
                    <ul>
                      {technologyList2.map((val, index) => (
                        <li key={index}>
                          <Link to={`/${val.path}`}>{val.name}</Link>
                        </li>
                      ))}
                    </ul>
                    <ul>
                      {technologyList.map((val, index) => (
                        <li key={index}>
                          <Link to={`/technologies/#${val.path}`}>
                            {val.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li>
                  <Link to='#'>
                    <img
                      src={technologiesmenuIcon}
                      alt='technologiesmenuIcon'
                      className='icon'
                    />
                    {headerData.industry.name}
                  </Link>
                  <ul>
                    {headerData.industry.value.map((data, index) => (
                      <li key={index}>
                        <Link to={`/industries/#${data.path}`}>
                          {data.val}{' '}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link to='#' className='dropdown-toggle'>
                {headerData.platform.name}
              </Link>
              <ul className=''>
                {headerData.platform.value.map((data, index) =>
                  data.val === 'AWS' ? (
                    <li key={index}>
                      <Link to={`/${data.path}/`}>{data.val} </Link>
                    </li>
                  ) : (
                    <li key={index}>
                      <Link to={`/cloud-platforms/#${data.path}`}>
                        {data.val}{' '}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </li>
            <li>
              <Link to='#' className='dropdown-toggle'>
                {headerData.resources.name}
              </Link>
              <ul className=''>
                <li>
                  <Link to={`/resource/blog/`}>
                    <PressnewsMenu />
                    {getResourcesList[2]}
                  </Link>
                </li>
                <li>
                  <Link to={`/resource/white-papers/`}>
                    <WhitePapermenu />
                    {getResourcesList[3]}
                  </Link>
                </li>
                <li>
                  {locale === 'sv' && (
                    <Link to={`/resource/press-release/`}>
                      <WhitePapermenu />
                      {getResourcesList[4]}
                    </Link>
                  )}
                </li>
              </ul>
            </li>
            <li>
              <Link to='#' className='dropdown-toggle'>
              {headerData.whyopsio.name}
              </Link>
              <ul className=''>
                <li>
                  <Link to={`/why-opsio/`}>
                  <LatestnewsMenu />
                          {headerData.whyopsio.value[0]}{" "}
                  </Link>
                </li>
                <li>
                  <Link to={`/successstories/`}>
                  <BlogsMenu />
                          {headerData.whyopsio.value[1]}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              {locale === 'sv' ? (
                <Link to={`/contact-us/`}>Kontakta oss</Link>
              ) : (
                <Link to={`/contact-us/`}>Contact Us</Link>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div className='mobile_nav'>
        <div className='wrapper'>
          <input type='radio' name='slider' id='menu-btn' />
          <input type='radio' name='slider' id='close-btn' />
          <ul className='nav-links'>
            <label htmlFor='close-btn' className='navbtn close-btn'>
              <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='#fff' viewBox='0 0 24 24'>
                <path d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z' />
              </svg>
            </label>
            <li>
              <input type='checkbox' id='showSolutions' />
              <label htmlFor='showSolutions' className='mobile-item'>
                Solutions
              </label>
              <ul className='drop-menu'>
                <li>
                  <input type='checkbox' id='showServices' />
                  <h6>{getSolutionList.name}</h6>
                  <label htmlFor='showServices' className='mobile-item'>
                    <img
                      src={servicemenuIcon}
                      alt='servicemenuIcon'
                      className='icon'
                    />
                    {getSolutionList.value[0]}
                  </label>
                  <ul className='drop-menu menu2'>
                    <li>
                      <input type='checkbox' id='showAWSManagedServices' />
                      <label
                        htmlFor='showAWSManagedServices'
                        className='mobile-item'
                      >
                        <img
                          src={AwsIcon}
                          alt='AwsIcon'
                          className='icon'
                        />
                        {getSolutionList.value[1]}
                      </label>
                      <ul className='drop-menu menu2'>
                        <div className='solutionsubmenu'>
                          <div className='ruby-row'>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAWS[0].name}</label>
                              {serviceListAWS[0].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAWS[1].name}</label>
                              {serviceListAWS[1].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAWS[2].name}</label>
                              {serviceListAWS[2].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAWS[3].name}</label>
                              {serviceListAWS[3].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          </div>
                        </div>
                      </ul>
                    </li>
                    <li>
                      <input type='checkbox' id='showAzureManagedServices' />
                      <label
                        htmlFor='showAzureManagedServices'
                        className='mobile-item'
                      >
                        <img
                          src={AzureIcon}
                          alt='AzureIcon'
                          className='icon'
                        />
                        {getSolutionList.value[2]}
                      </label>
                      <ul className='drop-menu menu2'>
                        <div className='solutionsubmenu'>
                          <div className='ruby-row'>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAzure[0].name}</label>
                              {serviceListAzure[0].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAzure[1].name}</label>
                              {serviceListAzure[1].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListAzure[2].name}</label>
                              {serviceListAzure[2].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListAzure[3].name}</label>
                              {serviceListAzure[3].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          </div>
                        </div>
                      </ul>
                    </li>
                    <li>
                      <input type='checkbox' id='showCloudServices' />
                      <label
                        htmlFor='showCloudServices'
                        className='mobile-item'
                      >
                        <img
                          src={CloudIcon}
                          alt='CloudIcon'
                          className='icon'
                        />
                        {getSolutionList.value[3]}
                      </label>
                      <ul className='drop-menu menu2'>
                        <div className='solutionsubmenu'>
                          <div className='ruby-row'>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListGCP[0].name}</label>
                              {serviceListGCP[0].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListGCP[1].name}</label>
                              {serviceListGCP[1].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='ruby-col-6'>
                            <ul>
                              <label>{serviceListGCP[2].name}</label>
                              {serviceListGCP[2].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                            <ul>
                              <label>{serviceListGCP[3].name}</label>
                              {serviceListGCP[3].value.map((val, index) => (
                                <li key={index}>
                                  <Link to={`/${val.path}/`}>{val.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          </div>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <input type='checkbox' id='showTechnologies' />
                  <label htmlFor='showTechnologies' className='mobile-item'>
                    <img
                      src={technologiesmenuIcon}
                      alt='technologiesmenuIcon'
                      className='icon'
                    />
                    {getSolutionList.value[4]}
                  </label>
                  <ul className='drop-menu menu2'>
                  {technologyList2.map((val, index) => (
                        <li key={index}>
                          <Link to={`/${val.path}`}>{val.name}</Link>
                        </li>
                      ))}
                       {technologyList.map((val, index) => (
                        <li key={index}>
                          <Link to={`/technologies/#${val.path}`}>
                            {val.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
                <li>
                  <input type='checkbox' id='showIndustries' />
                  <label htmlFor='showIndustries' className='mobile-item'>
                    <img
                      src={technologiesmenuIcon}
                      alt='technologiesmenuIcon'
                      className='icon'
                    />
                    {headerData.industry.name}
                  </label>
                  <ul className='drop-menu menu2'>
                  {headerData.industry.value.map((data, index) => (
                      <li key={index}>
                        <Link to={`/industries/#${data.path}`}>
                          {data.val}{' '}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <input type='checkbox' id='showPlatforms' />
              <label htmlFor='showPlatforms' className='mobile-item'>
              {headerData.platform.name}
              </label>
              <ul className='drop-menu'>
              {headerData.platform.value.map((data, index) =>
                  data.val === 'AWS' ? (
                    <li key={index}>
                      <Link to={`/${data.path}/`}>{data.val} </Link>
                    </li>
                  ) : (
                    <li key={index}>
                      <Link to={`/cloud-platforms/#${data.path}`}>
                        {data.val}{' '}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </li>
            <li>
              <input type='checkbox' id='showResources' />
              <label htmlFor='showResources' className='mobile-item'>
              {headerData.resources.name}
              </label>
              <ul className='drop-menu'>
                <li>
                <Link to={`/resource/blog/`}>
                    <PressnewsMenu />
                    {getResourcesList[2]}
                  </Link>
                </li>
                <li>
                  <Link to={`/resource/white-papers/`}>
                    <WhitePapermenu />
                    {getResourcesList[3]}
                  </Link>
                </li>
                <li>
                  {locale === 'sv' && (
                    <Link to={`/resource/press-release/`}>
                      <WhitePapermenu />
                      {getResourcesList[4]}
                    </Link>
                  )}
                </li>
              </ul>
            </li>
            <li>
              <input type='checkbox' id='showOpsio' />
              <label htmlFor='showOpsio' className='mobile-item'>
              {headerData.whyopsio.name}
              </label>
              <ul className='drop-menu'>
              <li>
                  <Link to={`/why-opsio/`}>
                  <LatestnewsMenu />
                          {headerData.whyopsio.value[0]}{" "}
                  </Link>
                </li>
                <li>
                  <Link to={`/successstories/`}>
                  <BlogsMenu />
                          {headerData.whyopsio.value[1]}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
                {locale === 'sv' ? (
                  <Link to={`/contact-us/`}>Kontakta oss</Link>
                ) : (
                  <Link to={`/contact-us/`}>Contact Us</Link>
                )}
            </li>
            <div className='langOpt'>
              <LanguageIcon
                flag={
                  langTitle === flags['en']
                    ? 'en'
                    : langTitle === flags['sv']
                    ? 'sv'
                    : 'in'
                }
              />
              <NavDropdown
                title={langTitle}
                id='collasible-nav-dropdown-13'
                className='landropMenu'
                show={show3}
                onToggle={showDropdown3}
              >
                <div className='lanMenu'>
                  {slug === '/' ? (
                    <Nav.Item>
                      <a
                        className='nav-link'
                        onClick={() => setLangTitle(flags['sv'])}
                        href={`https://opsio.se/`}
                        eventKey='first'
                      >
                        <LanguageIcon flag={'sv'} />
                        {flags['sv']}
                      </a>
                      <a
                        className='nav-link'
                        onClick={() => setLangTitle(flags['en'])}
                        href={`https://opsiocloud.com/`}
                        eventKey='first'
                      >
                        <LanguageIcon flag={'en'} />
                        {flags['en']}
                      </a>
                      <a
                        className='nav-link'
                        onClick={() => setLangTitle(flags['en-IN'])}
                        href={`https://opsio.in/`}
                        eventKey='first'
                      >
                        <LanguageIcon flag={'in'} />
                        {flags['en-IN']}
                      </a>
                    </Nav.Item>
                  ) : (
                    <Nav.Item>
                      <a
                        className='nav-link'
                        onClick={() => setLangTitle(flags['sv'])}
                        href={`https://opsio.se/${slug}/`}
                        eventKey='first'
                      >
                        <LanguageIcon flag={'sv'} />
                        {flags['sv']}
                      </a>
                      <a
                        className='nav-link'
                        onClick={() => setLangTitle(flags['en'])}
                        href={`https://opsiocloud.com/${slug}/`}
                        eventKey='first'
                      >
                        <LanguageIcon flag={'en'} />
                        {flags['en']}
                      </a>
                      <a
                        className='nav-link'
                        onClick={() => setLangTitle(flags['en-IN'])}
                        href={`https://opsio.in/${slug}/`}
                        eventKey='first'
                      >
                        <LanguageIcon flag={'in'} />
                        {flags['en-IN']}
                      </a>
                    </Nav.Item>
                  )}
                </div>
              </NavDropdown>
            </div>
          </ul>
          <label htmlFor='menu-btn' className='navbtn menu-btn'>
            <svg width='23' height='15' viewBox='0 0 23 15' fill='none'>
              <path
                d='M23 14.0898H3.83333V12.8141H23V14.0898ZM23 7.72773H8.94444V6.45196H23V7.72773ZM23 1.36561H0V0.0898438H23V1.36561Z'
                fill='white'
              ></path>
            </svg>
          </label>
        </div>
      </div>
    </div>
  )
}
export default Navigation
