import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "../styles/footer.scss";
import { Helmet } from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import { graphql, useStaticQuery } from "gatsby";

const Layout = (props) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookieConsentShown")) {
      setShowCookieConsent(true);
      localStorage.setItem("cookieConsentShown", true);
    }
  }, []);

  // useEffect(() => {
  //   // Load reCAPTCHA script here when it's needed
  //   if (!recaptchaLoaded) {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://www.google.com/recaptcha/api.js?render=6LcbmXUdAAAAAM8BACZCq5rzXPHfn1BpVeNv5f5o";
  //     script.async = true;
  //     script.onload = () => setRecaptchaLoaded(true);
  //     document.head.appendChild(script);
  //   }
  // }, [recaptchaLoaded]);

  const data = useStaticQuery(graphql`
    query Layout {
      allStrapiLayout {
        edges {
          node {
            locale
            navbar {
              whyopsio {
                name
                value
              }

              industry {
                name
                value {
                  path
                  val
                }
              }

              platform {
                value {
                  path
                  val
                }
                name
              }
              contact
              search
              searchNoOptions
              resources {
                name
                value
              }
              footer {
                heading
                copyright
                changePrivacy
                support {
                  createTicket
                  contactSupport
                }
                contact {
                  email
                  phone
                  address1
                  address2
                }
                values {
                  COMPANY {
                    name
                    src
                  }
                  INDUSTRIES {
                    name
                    src
                  }
                  PLATFORMS {
                    name
                    src
                  }
                  RESOURCES {
                    name
                    src
                  }
                  SERVICESAWS {
                    name
                    src
                  }
                  SERVICESAZURE {
                    name
                    src
                  }
                  SERVICESGCP {
                    name
                    src
                  }
                  TECHNOLOGIES {
                    name
                    src
                  }
                  TECHNOLOGIES2 {
                    name
                    src
                  }
                  CLOUDSOLUTIONS {
                    name
                    src
                  }
                  DATAAI {
                    name
                    src
                  }
                  SECURITYCOMPLIANCE {
                    name
                    src
                  }
                  CODECRAFTING {
                    name
                    src
                  }
                  WORK {
                    name
                    src
                  }
                  CONTACTUS {
                    name
                    src
                  }
                }
              }
              suggestion

              solution {
                name
                data {
                  name
                  servicesAWS {
                    name
                    value {
                      name
                      path
                    }
                  }
                  servicesAzure {
                    name
                    value {
                      name
                      path
                    }
                  }
                  servicesGCP {
                    name
                    value {
                      name
                      path
                    }
                  }
                  technologies {
                    name
                    path
                  }
                  technologies2 {
                    name
                    path
                  }
                  value
                }
              }
              requirements {
                btn
                email
                des
                title
              }
            }
          }
        }
      }
    }
  `).allStrapiLayout.edges;
  const getData = data.filter((node) => node.node.locale === props.locale);
  return (
    <>
      <Helmet>
        {props.locale === "en" && (
          <script
            async
            src="//clickiocmp.com/t/consent_235315.js"
            type="text/javascript"
          />
        )}
        {props.locale === "se" && (
          <script
            async
            src="//clickiocmp.com/t/consent_235316.js"
            type="text/javascript"
          />
        )}
        {props.locale === "en-IN" && (
          <script
            async
            src="//clickiocmp.com/t/consent_235314.js"
            type="text/javascript"
          />
        )}
      </Helmet>
      <Header
        locale={props.locale}
        slug={props.slug}
        headerData={getData.length > 0 && getData[0].node.navbar}
      />
      {props.children}
      <Footer
        data={getData.length > 0 && getData[0].node.navbar.footer}
        requirements={getData.length > 0 && getData[0].node.navbar.requirements}
        locale={props.locale}
        hideCta={props.hideCta}
      />
    </>
  );
};

export default Layout;
